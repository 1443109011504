// ハンバーガーメニュー
$(document).ready(function(){
	var state = false;
	$('#js_GNav__menuBtn').on('click', function() {
		$(this).toggleClass('-open');
		$(this).toggleClass('-close');
		if(state == false) {
			$('body').css('overflow', 'hidden');
			state = true;
		} else {
			$('body').css('overflow', 'auto');
			state = false;
		}
	});
});


$(document).ready(function(){
	$('.GNav__menuMainLink').on('click', function() {
		$('#js_GNav__menuBtn').toggleClass('-open');
		$('#js_GNav__menuBtn').toggleClass('-close');
		$('body').css('overflow', 'auto');
	});
});


$(document).ready(function(){
	$('.GNav__menuCTALink').on('click', function() {
		$('#js_GNav__menuBtn').toggleClass('-open');
		$('#js_GNav__menuBtn').toggleClass('-close');
		$('body').css('overflow', 'auto');
	});
});


// コピーライト
$(document).ready(function(){
	var todayData = new Date();
	var thisYear = todayData.getFullYear();
	$('#js_copyYear').text('©' + ' ' + thisYear + ' 中小企業ポータル');
});


// タブ
$(document).ready(function(){
	$('.js_tab .tab-title').click(function() {
		var num = $('.js_tab .tab-title').index(this);
		$('.tab-content').removeClass('-active');
		$('.tab-content').eq(num).addClass('-active');
		$('.js_tab .tab-title').removeClass('-active');
		$(this).addClass('-active')
	});
});


// トグル
$(document).ready(function(){
	$('.js_toggleBtn').click(function() {
		$(this).toggleClass('-active');
		$(this).next('.js_toggleContent').slideToggle();
	});
});


// スムーズスクロール
$(document).ready(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href = $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});


// フォームのクリアボタン
$(document).ready(function(){
	$('.btn-clear').click(function() {
		$('.form-list-item.-keyword .form-list-item-data-content').val('');
		$('.form-list-item.-keyword .form-list-item-data-content').focus();
	});
});


// フォーム「補助金・助成金検索」の「公募受付期間（締切日）」の
// チェックボックス「締め切りを過ぎた補助金の情報も含めてすべて表示する。」
$(document).ready(function(){
	$('.search-all').change(function() {
		if ( $(this).is(':checked') ){
			// チェックが入っていたら、「過去全部」〜「終了日」までの補助金情報をすべて表示
			$('input[name="cf_limit_keyword_6"]').prop('disabled', true);
		} else {
			// チェックが入っていなかったら、「今日」〜「終了日」までの補助金情報のみを表示
			$('input[name="cf_limit_keyword_6"]').prop('disabled', false);
		}
	});
});


// 「目次」の▼ボタン
$(document).ready(function(){
	$('.ez-toc-toggle').click(function() {
		$(this).toggleClass('-open');
		$(this).toggleClass('-close');
	});
});
